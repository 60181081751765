/* Wrap each IP address entry in a flex container */
.ip-address-entry {
    display: flex;
    align-items: center;
    /* Vertically align input */
    margin-bottom: 10px;
    /* Add spacing between IP address entries */
}

/* Style the IP address input field */
.ip-address-input {
    flex: 1;
    /* Allow the input field to grow and take available space */
    margin-right: 10px;
    /* Add spacing between input and delete button */
}

/* Style the "Delete IP Address" button (cross) */
.delete-ip-address-button {
    background-color: transparent;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc3545;
    /* Red color for delete buttons */
}

/* Remove display: flex from .exporter-option */
.exporter-option {
    margin-bottom: 10px;
    font-size: 16px; /* Adjust font size as needed */
}
/* Style the scheme-row to display its content in one row */
.scheme-row {
    display: flex;
    align-items: center;
}

.scheme-name-label {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

/* Style the exporter-row to display its content in one row */
.exporter-row {
    display: flex;
    align-items: center;
}

.exporter-choosing-checkbox {
    display: flex;
    padding-right: 10%;
    margin-right: 10px;
}

.exporter-name-label {
    margin-right: 10px;
}

/* Style for toggle switches */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px; /* Set a fixed width for the switch */
    height: 24px;
    cursor: pointer;
}

.toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px; /* Set the same width as the switch */
    height: 24px;
    background-color: #2e2e2e;
    border-radius: 24px;
}

.toggle-slider:before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    top: 3px;
    left: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
    transform: translateX(0); /* Initial position */
}

/* Use 'transform' to move the circle */
.toggle-switch input:checked + .toggle-slider {
    background-color: #dc6e00; /* Move it to the right */
}

/* Use 'transform' to move the circle */
.toggle-switch input:checked + .toggle-slider:before {
    transform: translateX(34px); /* Move it to the right */
}

.toggle-switch input {
    display: none; /* Hide the actual checkbox input */
}

/* Style for authentication toggle switches */
.auth-option-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}

.auth-option-annotation {
    margin-right: 10px;
}

.auth-toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px; /* Set a fixed width for the switch */
    height: 24px;
    cursor: pointer;
}

.auth-toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px; /* Set the same width as the switch */
    height: 24px;
    background-color: #2e2e2e;
    border-radius: 24px;
}

.auth-toggle-slider:before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    top: 3px;
    left: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
    transform: translateX(0); /* Initial position */
}

/* Use 'transform' to move the circle */
.auth-toggle-switch input:checked + .auth-toggle-slider{
    background-color: #dc6e00; /* Move it to the right */
}

/* Use 'transform' to move the circle */
.auth-toggle-switch input:checked + .auth-toggle-slider:before {
    transform: translateX(34px); /* Move it to the right */
}

.auth-toggle-switch input {
    display: none; /* Hide the actual checkbox input */
}

.notification-option-row {
    display: flex;
}

.notification-source-row {
    display: flex;
}

/* Style for notification toggle switch */
.notification-toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px; /* Set a fixed width for the switch */
    height: 24px;
    cursor: pointer;
}

.notification-toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px; /* Set the same width as the switch */
    height: 24px;
    background-color: #2e2e2e;
    border-radius: 24px;
}

.notification-toggle-slider:before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    top: 3px;
    left: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
    transform: translateX(0); /* Initial position */
}

.notification-option-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}

.notification-source-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}

.notification-toggle-switch input:checked + .notification-toggle-slider {
    background-color: #dc6e00; /* Move it to the right */
}

/* Use 'transform' to move the circle */
.notification-toggle-switch input:checked + .notification-toggle-slider:before {
    transform: translateX(34px); /* Move it to the right */
}

.notification-toggle-switch input {
    display: none; /* Hide the actual checkbox input */
}



/* Style the username and password input fields */
.auth-input {
    /* margin-top: 10px; */
    display: flex; /* Display input fields in one row */
    flex-direction: column; /* Place input fields in a column layout */
}

/* Style the labels for username and password fields */
.auth-input label {
    font-weight: bold;
    margin-top: 10px;
}

/* Wrap each email entry in a flex container */
.email-entry {
    display: flex;
    align-items: center;
    /* Vertically align input and button */
    margin-bottom: 10px;
    /* Add spacing between email entries */
}

/* Style the email input field */
.email-input {
    flex: 1;
    /* Allow the input field to grow and take available space */
    margin-right: 10px;
    /* Add spacing between input and button */
}

/* Style the "Delete Email" button (cross) */
.delete-email-button {
    background-color: transparent;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc3545;
    /* Red color for delete buttons */
}

.newproject-form {
    background-color: #2e2e2e;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Form section headings */
.form-group>label {
    font-weight: bold;
}


/* Responsive form layout */
.form-group {
    margin-bottom: 20px;
}

/* Add margin between input and button in the same div */
.form-group>button {
    margin-top: 10px;
}

/* Add space between checkboxes and their labels */
.form-check-label {
    margin-left: 5px;
}

.form-group {
    padding: 15px;
    background-color: #3f3f3f;
    border-radius: 10px;
}

/* Style the delete buttons */
.btn-danger {
    background-color: #dc3545;
    /* Red color for delete buttons */
    color: white;
    margin-bottom: 5px;
    margin-top: 5px;
}

.reject-submit-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Style the submit button */
.btn-primary {
    background-color: #dc6e00;
    /* Blue color for submit button */
    color: white;
}

.submit-newproject-button {
    background-color: transparent;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc6e00;
    /* Red color for delete buttons */
    transition: background-color 0.3s;
    padding: 10px 20px;
    border-radius: 3px;
}

.submit-newproject-button:hover {
    background-color: #ff00ff;
    color: #ffffff;
    box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
}

.clear-newproject-form-button {
    background-color: transparent;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #ff0000;
    /* Red color for delete buttons */
    transition: background-color 0.3s;
    padding: 10px 20px;
    border-radius: 3px;
}


/* Optional: Style the IP address input fields */
.ip-address {
    width: 100%;
}

/* Optional: Style the notification options */
.form-check {
    margin-top: 5px;
}

/* Optional: Style the Telegram input fields */
.form-control[placeholder="Telegram Chat ID"],
.form-control[placeholder="Telegram Bot Token"] {
    margin-top: 5px;
}

.form-control[placeholder="Notification email"] {
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-control[placeholder="Discord address"]{
    margin-top: 5px;
    margin-bottom: 5px;
}
/* Optional: Style the form switch (Yes/No) */
.form-switch {
    display: flex;
    align-items: center;
}
.server-setup-form {
    background-color: #2e2e2e;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.upper-label {
    margin-bottom: 10px;
}

.middle-label {
    margin-top: 20px;
    margin-bottom: 10px;
}

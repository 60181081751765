.grafana-dashboard-option-section {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.grafana-dashboard-option-row {
    justify-content: space-around;
    display: flex;
}

.grafana-dashboard-option-button {
    background-color: #3f3f3f;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc6e00;
    /* Red color for delete buttons */
    transition: background-color 0.3s;
    padding: 10px 20px;
    border-radius: 3px;
}

.grafana-dashboard-option-button:hover {
    background-color: #ff00ff;
    color: #ffffff;
    box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
}
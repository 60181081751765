/* Sidebar Styles */
.sidebar {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100vh;
    width: 300px;
    background-color: #222222;
    transition: left 0.3s ease;
    z-index: 1000;
}

.sidebar.show {
    left: 0;
}

.arrow-container {
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #111111;
    color: #ffffff;
    cursor: pointer;
    z-index: 1001;
    transition: left 0.3s ease;
}

.arrow-container.open {
    left: 320px;
}

.arrow-btn {
    font-size: 20px;
}

.arrow-left {
    transform: rotate(180deg);
}

.arrow-icon {
    font-size: 20px;
    display: inline-block;
    transform-origin: center;
    transition: transform 0.3s ease;
}

/* Rest of the styles for items inside opened sidebar */



/* Items inside opened sidebar */
.sidebar-menu {
    list-style: none;
    padding: 0;
}

.sidebar-menu-item {
    margin-bottom: 10px;
    background-color: #333333;
}

.sidebar-menu-link {
    display: block;
    padding: 8px;
    color: #dc6e00;
    text-decoration: none;
}

.sidebar-menu-link:hover {
    background-color: #f1f1f1;
}

/* Updated Styles */
.sidebar-menu-item.active {
    background-color: #dc6e00;
    color: #000000;
}

.sidebar-menu-item.active .sidebar-menu-link {
    color: #000000;
}
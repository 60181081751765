.profile-container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  .profile-info-row {
    display: flex;
    margin-bottom: 10px;
  }
  
  .profile-info-label {
    flex: 1;
    font-weight: bold;
  }
  
  .profile-info-value {
    flex: 2;
  }
  
  .change-password-button {
    background-color: #3f3f3f;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc6e00;
    /* Red color for delete buttons */
    transition: background-color 0.3s;
    padding: 10px 20px;
    border-radius: 3px;
  }

  .change-password-button:hover {
    background-color: #ff00ff;
    color: #ffffff;
    box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
  }
  
  .change-password-fields {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .password-field {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .confirm-password-change-button {
    background-color: #3f3f3f;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc6e00;
    /* Red color for delete buttons */
    transition: background-color 0.3s;
    padding: 10px 20px;
    border-radius: 3px;
  }

  .confirm-password-change-button:hover {
    background-color: #ff00ff;
    color: #ffffff;
    box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
  }
  
  .password-change-message {
    margin-top: 10px;
    font-style: italic;
    color: #dc3545;
  }
  
.grafana-dashboard-creation-section {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-align: center;
}

.grafana-dashboard-upload-row {
    /* justify-content: space-around; */
    /* display: flex; */
    width: 450px;
    background-color: #3f3f3f;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    padding: 2rem;
    text-align: center;
    /* background: #ffffff; */
    border-radius: 25px;
    border: 3px dashed #dc6e00;;
    margin-bottom: 20px;
}

.grafana-dashboard-raw-row {
    /* justify-content: space-around; */
    /* display: flex; */
    background-color: #3f3f3f;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding: 10px;
    align-items: center;
    position: relative;
    text-align: center;
}

.grafana-dashboard-creation-button {
    background-color: #3f3f3f;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc6e00;
    /* Red color for delete buttons */
    transition: background-color 0.3s;
    padding: 10px 20px;
    border-radius: 3px;
}

.grafana-dashboard-creation-button:hover {
    background-color: #ff00ff;
    color: #ffffff;
    box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
}

.upload-file-input {
    text-align: center;
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
}

.upload-file-input input {
    display: inline;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.textarea-json {
    width: 80%;
}

.delete-file-button {
    background-color: transparent;
    /* Transparent button background */
    border: none;
    /* Remove button border */
    font-size: 18px;
    /* Adjust the size of the cross */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    color: #dc3545;
}

.notification-container {
    position: absolute;
    top: 0;
    right: 0;
  }
  
.notification {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
  }
  
  .success {
    background-color: #4CAF50;
    color: white;
  }
  
  .error {
    background-color: #f44336;
    color: white;
  }
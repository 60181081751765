.navbar {
    padding: 0 0px;
    min-height: auto; /* Adjust the height as desired */
    width: 100%;
}

.navbar-brand {
    font-size: 18px;
}

.navbar-toggler-icon {
    padding: 0.25rem;
}

.navbar-nav {
    margin-top: 0;
}

.navbar-nav .nav-item {
    margin-right: 10px;
    margin-left: 10px;
    order: 2; /* Change the order to 2 (default is 0) */
}

.navbar-nav .nav-item:last-child {
    margin-right: 0;
}

.navbar-toggler {
    order: 1; /* Change the order to 1 (default is 0) */
}

.navbar-nav .nav-item .logout-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font: inherit;
    color: inherit;
  }
.fetch-user-button {
  background-color: transparent;
  /* Transparent button background */
  border: none;
  /* Remove button border */
  font-size: 18px;
  /* Adjust the size of the cross */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  color: #dc6e00;
  /* Red color for delete buttons */
  transition: background-color 0.3s;
  padding: 10px 20px;
  border-radius: 3px;
  text-align: center;
}

.fetch-user-button:hover {
  background-color: #ff00ff;
  color: #ffffff;
  box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
  text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
}

.notification {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}

.success {
  background-color: #4CAF50;
  color: white;
}

.error {
  background-color: #f44336;
  color: white;
}
.delete-project-form {
    background-color: #2e2e2e;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-group {
    padding: 15px;
    background-color: #3f3f3f;
    border-radius: 10px;
}

/* Form section headings */
.form-group>label {
    font-weight: bold;
}


/* Responsive form layout */
.form-group {
    margin-bottom: 20px;
}

/* Style for the React Modal */

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important; /* Semi-transparent black background */
    display: flex !important;
    justify-content: center !important; /* Center horizontally */
    align-items: center !important; /* Center vertically */
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 1000 !important; /* Ensure it's above other content */
}

.ReactModal__Content {
    background-color: #2e2e2e !important;
    border-radius: 5px !important;
    padding: 20px !important;
    max-width: 400px !important; /* Adjust the width as needed */
    text-align: center !important;
    position: static !important;
    top: 40px !important;
    left: 40px !important;
    right: 40px !important;
    bottom: 40px !important;
    border: 1px solid #ccc !important;
    overflow: auto !important;
    outline: none !important;
    padding: 20px !important
}

.make-sure-text {
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
}

.make-sure-text-2 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
}


/* Style for buttons inside the modal */
.ReactModal__Content button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

/* Style for the OK button */
.ReactModal__Content .btn-primary {
    background-color: #007bff; /* Blue color for OK button */
    color: #fff;
    border: none;
}

/* Style for the Cancel button */
.ReactModal__Content .btn-danger {
    background-color: #dc3545; /* Red color for Cancel button */
    color: #fff;
    border: none;
}
.grafana-user-section {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.grafana-user-action-section {
    padding: 15px;
    background-color: #3f3f3f;
    border-radius: 10px;
    margin-top: 10px;
}

.form-group-grafana-users {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
}

.notification {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
  }
  
  .success {
    background-color: #4CAF50;
    color: white;
  }
  
  .error {
    background-color: #f44336;
    color: white;
  }
/* Body Styles */
body {
    background-color: #000000;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #ffffff;
}

.container {
    margin: 0 auto;
    background-color: #222222;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

h1 {
    text-align: center;
    color: #dc6e00;
    text-shadow: 2px 2px 8px rgb(145, 65, 0);
}

/* Form Styles */

.button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.square-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background-color: #222222;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.square-button:hover {
    background-color: #333333;
    color: #dc6e00;
}

.button-inner span {
    font-size: 18px;
    text-shadow: 1px 1px 4px rgb(145, 65, 0);
}

.adm-button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.adm-square-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 200px;
    border-radius: 10px;
    background-color: #222222;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.adm-square-button:hover {
    background-color: #333333;
    color: #dc6e00;
}

.adm-button-inner span {
    font-size: 18px;
    text-shadow: 1px 1px 4px rgb(145, 65, 0);
}

label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #dc6e00;
    text-shadow: 1px 1px 4px rgb(145, 65, 0);
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #555555;
    outline: none;
    transition: border-color 0.3s;
    background-color: #222222;
    color: #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 8px rgb(145, 65, 0);
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #dc6e00;
    box-shadow: 0 0 12px #dc6e00;
}

input[type="text"]:invalid:required,
input[type="email"]:invalid:required,
input[type="password"]:invalid:required {
    border-color: #ff0000;
}

input[type="submit"] {
    background-color: #dc6e00;
    color: #000000;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-shadow: 1px 1px 4px rgb(145, 65, 0);
    box-shadow: 0 0 8px rgb(145, 65, 0);
}

input[type="submit"]:hover {
    background-color: #ff00ff;
    color: #ffffff;
    box-shadow: 0 0 12px rgba(255, 0, 255, 0.7);
    text-shadow: 2px 2px 8px rgba(255, 0, 255, 0.7);
}

.content-container {
    margin-top: 30px;
     /*margin-left: 320px; Updated margin-left to accommodate the wider sidebar */
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.dashboard-button-section {
    margin-bottom: 10px;
    width: 80%;
    text-align: center;
}

.dashboard-button {
    width: 100%;
}

.dashboard-section {
    background-color: #333333;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
}

.user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.user-button-section {
    margin-bottom: 10px;
    width: 80%;
    text-align: center;
}

.user-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    background-color: #333333;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    font-size: x-large;
}

.user-button:hover {
    background-color: #444444;
}

.user-button:active {
    background-color: #555555;
}

.user-button span {
    font-size: 16px;
    text-shadow: 1px 1px 4px rgb(145, 65, 0);
}

.user-section {
    background-color: #333333;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Table Styles */
#users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

#users-table th,
#users-table td {
    padding: 10px;
    border: 1px solid #555555;
    text-align: left;
}

#users-table th {
    background-color: #222222;
    color: #ffffff;
}

#users-table tr:nth-child(even) {
    background-color: #333333;
}

#users-table tr:hover {
    background-color: #444444;
}

#users-table td:last-child {
    white-space: nowrap;
}

